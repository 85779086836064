<template>
  <v-form
    :key="organism.id"
    ref="organismForm"
    v-model="valid"
    :readonly="!$auth.check({ organisms: 'edit' })"
    :disabled="organism.invalid"
    @submit.prevent="updateOrganism"
  >
    <v-tabs
      v-model="tab"
      background-color="primary lighten-1"
      grow
      dark
    >
      <v-tab>
        <v-icon>fal fa-circle-info</v-icon>
      </v-tab>
      <v-tab :disabled="organism.invalid">
        <v-icon>fal fa-tachometer-alt</v-icon>
      </v-tab>
      <v-tab :disabled="organism.invalid">
        <v-icon>fal fa-shield-virus</v-icon>
      </v-tab>
      <v-tab :disabled="organism.invalid">
        <v-icon>fal fa-pills</v-icon>
      </v-tab>
      <v-tab :disabled="organism.invalid">
        <v-icon>fal fa-books</v-icon>
      </v-tab>
    </v-tabs>
    <v-tabs-items v-model="tab">
      <v-tab-item>
        <v-row class="pa-3">
          <v-col
            cols="12"
            class="mt-2 pb-0"
          >
            <v-text-field
              v-model="organism.name"
              :readonly="info"
              :label="$t('name') | capitalize"
              dense
            />
          </v-col>

          <v-col
            cols="12"
            class="py-0 px-0"
          >
            <v-card
              elevation="0"
              :color="organism.category ? 'amber lighten-5 pt-2' : null"
            >
              <v-col
                cols="12"
                class="pb-0"
              >
                <v-autocomplete
                  key="organism-category-list"
                  v-model="organism.category"
                  prepend-icon="fal fa-folder-open fa-sm"
                  :search-input.sync="categorySearch"
                  :loading="categoryLoading"
                  :items="categories"
                  label="Category"
                  item-text="name"
                  item-value="id"
                  hide-no-data
                  cache-items
                  return-object
                  clearable
                  dense
                  @click:clear="organism.category = null"
                >
                  <template #prepend>
                    <v-icon size="20">
                      fal fa-folder-open
                    </v-icon>
                  </template>
                  <template #append-outer>
                    <v-dialog
                      v-model="newCategoryDialog"
                      width="350"
                    >
                      <template #activator="{ on }">
                        <v-btn
                          icon
                          small
                          v-on="on"
                        >
                          <v-icon small>
                            fal fa-plus-circle
                          </v-icon>
                        </v-btn>
                      </template>
                      <v-card>
                        <v-card-text
                          class="pt-2 pb-0"
                        >
                          <v-text-field
                            v-model="newCategoryName"
                            label="Category Name"
                          />
                        </v-card-text>
                        <v-divider />
                        <v-card-actions>
                          <v-spacer />
                          <v-btn
                            text
                            @click="newCategoryDialog = false"
                          >
                            {{ $t('Cancel') }}
                          </v-btn>
                          <v-btn
                            text
                            :loading="newCategoryLoading"
                            @click="addCategory"
                          >
                            {{ $t('Save') }}
                          </v-btn>
                        </v-card-actions>
                      </v-card>
                    </v-dialog>
                  </template>
                </v-autocomplete>
              </v-col>

              <v-col
                cols="12"
                class="py-0"
              >
                <source-select
                  :key="organism.category ? organism.category.id : organism.id"
                  v-model="significant_sources"
                  :exclude="controversial_sources"
                  label="Significant Sources"
                  prepend-icon="fas fa-exclamation-triangle"
                  dense
                  menu-props="auto, offsetY"
                />
              </v-col>

              <v-col
                cols="12"
                class="py-0"
              >
                <source-select
                  :key="organism.category ? organism.category.id : organism.id"
                  v-model="controversial_sources"
                  :exclude="significant_sources"
                  label="Controversial Sources"
                  prepend-icon="fal fa-exclamation-triangle"
                  dense
                  menu-props="auto, offsetY"
                />
              </v-col>

              <v-col>
                <v-switch
                  :key="organism.category ? organism.category.id : organism.id"
                  v-model="priority"
                  label="Has treatment priority"
                  dense
                />
              </v-col>
            </v-card>
          </v-col>

          <v-col
            cols="12"
            md="4"
            class="pb-0"
          >
            <v-select
              v-model="organism.class"
              :readonly="info"
              :items="classes"
              label="Class"
              item-text="name"
              dense
            >
              <template #item="{ item }">
                {{ item.name | capitalize }}
              </template>
              <template #selection="{ item }">
                {{ item.name | capitalize }}
              </template>
            </v-select>
          </v-col>

          <v-col
            cols="12"
            md="4"
            class="pb-0"
          >
            <v-text-field
              v-model="organism.NHSN_code"
              :readonly="info"
              label="NHSN code"
              dense
            />
          </v-col>

          <v-col
            cols="12"
            md="4"
            class="pb-0"
          >
            <v-text-field
              v-model="organism.SNOMED_code"
              :readonly="info"
              label="SNOMED code"
              dense
            />
          </v-col>

          <v-col
            cols="12"
            class="pb-0"
          >
            <v-text-field
              v-model="organism.NHSN_display_name"
              :readonly="info"
              label="NHSN name"
              dense
            />
          </v-col>

          <v-col
            cols="12"
            class="pb-0"
          >
            <v-text-field
              v-model="organism.SNOMED_full_name"
              :readonly="info"
              label="SNOMED full name"
              dense
            />
          </v-col>

          <v-col
            cols="12"
            class="pb-0"
          >
            <v-textarea
              xv-model="organism.notes"
              label="Notes"
              auto-grow
              :row-height="12"
              dense
              class="small-text"
              :value="organism.notes"
              @change="v => (organism.notes = v)"
            />
          </v-col>

          <v-col>
            <v-switch
              v-model="organism.invalid"
              label="Invalid organism"
              color="error"
              :disabled="false"
              dense
            />
          </v-col>
        </v-row>
      </v-tab-item>
      <v-tab-item>
        <v-col class="text-overline">
          ArkScore Factors
        </v-col>
        <v-list
          dense
        >
          <v-list-item
            v-for="factor in score_factors"
            :key="factor.value"
            dense
          >
            <v-list-item-action>
              <v-switch
                v-model="computed_score_factors"
                :value="factor.value"
                dense
              />
            </v-list-item-action>
            <v-list-item-content>
              {{ factor.text }}
              <v-list-item-subtitle v-if="factor.subtitle">
                {{ factor.subtitle }}
              </v-list-item-subtitle>
            </v-list-item-content>
          </v-list-item>
          <v-list-item v-if="computed_score_factors.includes('precautions_required')">
            <v-list-item-action />
            <v-list-item-content>
              <v-col
                v-for="precaution in precautions"
                :key="precaution"
                cols="6"
                class="py-0 my-0"
              >
                <v-checkbox
                  v-model="computed_precautions"
                  :value="precaution"
                  :label="precaution | capitalize"
                  class="py-0 my-0"
                  dense
                />
              </v-col>
            </v-list-item-content>
          </v-list-item>
        </v-list>
      </v-tab-item>
      <v-tab-item>
        <v-col class="text-overline d-flex justify-space-between">
          Relevant Resistance
          <v-spacer />
          <v-select
            :key="organism.category ? organism.category.id : organism.id"
            v-model="organism.relevant_resistances"
            :items="resistances"
            dense
            item-text="name.en"
            item-value="id"
            style="max-width: 50%"
            menu-props="offsetY, closeOnClick"
            multiple
            outlined
            prepend-inner-icon="fa fa-plus-circle success--text"
            return-object
          >
            <template #selection />
          </v-select>
        </v-col>
        <v-list
          dense
        >
          <template
            v-for="resistance in organism.relevant_resistances.filter(x => x.name).sort((a, b) => a.name.en.localeCompare(b.name.en))"
          >
            <v-list-item
              :key="resistance.id"
            >
              <v-list-item-content class="text-body-2">
                {{ resistance.name.en }}
              </v-list-item-content>
              <v-list-action>
                <v-btn-toggle
                  v-model="resistance.level"
                >
                  <v-btn
                    v-for="level in [1,2,3,4,5]"
                    :key="level"
                    :value="level"
                    :color="resistanceLevelColor(level)"
                    icon
                    tile
                    small
                  >
                    <v-icon
                      small
                      :color="resistanceLevelColor(level)"
                    >
                      {{ resistanceLevelIcon(level) }}
                    </v-icon>
                  </v-btn>
                </v-btn-toggle>
              </v-list-action>
            </v-list-item>
            <v-divider :key="resistance.id" />
          </template>
        </v-list>
      </v-tab-item>
      <v-tab-item>
        <v-col class="text-overline">
          Effective Drugs
        </v-col>
        <v-expansion-panels
          accordion
          tile
        >
          <v-expansion-panel
            v-for="source, index in this.$sources.filter(x => relevant_sources.includes(x.value))"
            :key="index"
            @change="!organism.effective_drugs[source.value] ? $set(organism.effective_drugs, source.value, []) : true"
          >
            <v-expansion-panel-header>
              {{ source.text }}
              <v-spacer />
              <template #actions>
                <source-icon
                  :key="source.value"
                  :source="source.value"
                  :size="16"
                />
              </template>
            </v-expansion-panel-header>
            <v-expansion-panel-content class="pa-0">
              <!-- {{ source.value }} -->
              <!-- {{ organism.effective_drugs }} -->
              <drug-picker
                v-if="organism.effective_drugs[source.value]"
                xv-model="organism.effective_drugs[source.value]"
                :value.sync="organism.effective_drugs[source.value]"
                prepend-inner-icon="fal fa-fw fa-pills"
                multiple
                hide-arkscore
                hide-selected
                no-display
                dense
                solo
                filled
                rounded
                no-hint
                :max-width="100"
                menu-props="closeOnClick, closeOnContentClick"
                label="Select Drug"
                class="xmx-4 mt-n2 mb-n5"
                @input="updateEffectiveDrugs($event, source.value)"
              />
              <draggable
                v-if="organism.effective_drugs[source.value]"
                :list="organism.effective_drugs[source.value]"
                handle=".handle"
                class="mx-n6"
                @update="updateEffectiveDrugs(organism.effective_drugs[source.value], source.value)"
              >
                <transition-group
                  type="transition"
                  name="flip-list"
                >
                  <template
                    v-for="(drug, index) in organism.effective_drugs[source.value]"
                  >
                    <v-list-item
                      :key="index"
                      class="mb-n3"
                      dense
                    >
                      <v-list-item-action
                        v-show="$auth.check({ organisms: 'edit' }) && organism.effective_drugs[source.value].length > 1"
                        class="handle mr-1"
                      >
                        <v-btn
                          icon
                          tile
                          small
                        >
                          <v-icon small>
                            mdi-menu
                          </v-icon>
                        </v-btn>
                      </v-list-item-action>
                      <!-- <v-list-item-avatar
                          v-show="organism.effective_drugs[source.value].length > 1"
                          color="grey lighten-2"
                          :size="24"
                          :class="{'ml-n5': $auth.check({ organisms: 'edit' })}"
                          class="mr-3 text-caption"
                          v-text="index + 1"
                        /> -->
                      <v-list-item-content>
                        <v-list-item-title>
                          {{ drug.name.en }}
                          <v-icon
                            v-if="drug.no_oral_option"
                            color="red lighten-2"
                            class="ml-1 mb-1"
                            small
                          >
                            fa-light fa-syringe
                          </v-icon>
                        </v-list-item-title>
                      </v-list-item-content>
                      <v-list-item-action
                        v-for="factor in pivotFactors"
                        :key="`${factor.field}_${drug.pivot[factor.field]}`"
                        class="mr-n3"
                      >
                        <v-btn
                          icon
                          tile
                          small
                          :disabled="!$auth.check({ organisms: 'edit' })"
                          @click="drug.pivot[factor.field] = !drug.pivot[factor.field]; updateEffectiveDrugs(organism.effective_drugs[source.value], source.value)"
                        >
                          <i
                            :class="[factor.icon, drug.pivot[factor.field] ? 'orange--text text--darken-1' : 'grey--text text--lighten-1']"
                            :title="factor.text"
                          />
                        </v-btn>
                      </v-list-item-action>
                      <v-list-item-action
                        v-if="$auth.check({ organisms: 'edit' })"
                        @click="organism.effective_drugs[source.value].splice(organism.effective_drugs[source.value].indexOf(drug), 1)"
                      >
                        <v-btn
                          icon
                          tile
                          small
                        >
                          <v-icon small>
                            mdi-close
                          </v-icon>
                        </v-btn>
                      </v-list-item-action>
                    </v-list-item>
                  </template>
                </transition-group>
              </draggable>
            </v-expansion-panel-content>
          </v-expansion-panel>
        </v-expansion-panels>
      </v-tab-item>
      <v-tab-item>
        <v-row class="pa-3">
          <v-col
            cols="12"
            class="mt-2 pb-0"
          >
            <v-select
              v-model="lang"
              label="Language"
              dense
              :items="[
                {
                  value: 'en', text: 'English', menu: 'en',
                },{
                  value: 'es', text: 'Español', menu: 'es',
                },{
                  value: 'fr', text: 'Français', menu: 'fr',
                },{
                  value: 'pt', text: 'Português', menu: 'pt',
                }
              ]"
            />
          </v-col>
          <v-col cols="12">
            <v-textarea
              v-model="organism.description[lang]"
              label="Description"
              dense
              auto-grow
            />
          </v-col>
          <v-col
            cols="12"
          >
            <v-divider />
            <v-col class="text-overline">
              References
            </v-col>
            <add-reference :references.sync="organism.references" />
          </v-col>
        </v-row>
      </v-tab-item>
    </v-tabs-items>
  </v-form>
</template>

<script>
  import Vue2Filters from 'vue2-filters';

  export default {
    components: {
      SourceIcon: () => import('@/components/source/SourceIcon.vue'),
      SourceSelect: () => import('@/components/source/SourceSelect.vue'),
      DrugPicker: () => import('@/components/drug/DrugPicker.vue'),
      AddReference: () => import('@/components/reference/AddReference.vue'),
      draggable: () => import('vuedraggable'),
    },
    mixins: [
      Vue2Filters.mixin,
    ],
    props: {
      open: Boolean,
      info: Boolean,
      organism: {
        type: Object,
        default: () => ({}),
      },
    },
    data () {
      return {
        drugs: null,
        tab: 'info',
        lang: 'en',
        valid: false,
        loading: false,
        categoryLoading: false,
        categorySearchText: null,
        categories: [],
        classes: [
          {
            name: 'bacteria',
            icon: 'fal fa-bacterium',
          },
          {
            name: 'fungus',
            icon: 'fa-kit fa-mushroom',
          },
          {
            name: 'parasite',
            icon: 'fal fa-bug',
          },
          {
            name: 'virus',
            icon: 'fal fa-virus',
          },
          {
            name: 'yeast',
            icon: 'fal fa-bread-slice',
          },
          {
            name: 'prion disease',
            icon: 'fal fa-egg-fried',
          },
        ],
        newCategoryDialog: false,
        newCategoryName: null,
        newCategoryLoading: false,
        score_factors: [
          { value: 'intrinsic_resistance', text: 'Intrinsic resistance/variable activity' },
          { value: 'other_site_of_infection', text: 'May lead to infections in other sites of the body' },
          { value: 'very_serious', text: 'May present as a serious infection' },
          { value: 'unknown_treatment', text: 'Ideal treatment regimen is unknown' },
          { value: 'dosage_adjustments', text: 'Dose and duration adjustments needed' },
          { value: 'precautions_required', text: 'Precautions required' },
        ],
        precautions: [
          'contact',
          'droplet',
          'airborne',
        ],
        resistances: [this.organism.relevant_resistances],
        pivotFactors: [
          {
            field: 'variable',
            text: 'Variable activity',
            icon: 'fa-light fa-square-v fa-lg',
          },
          {
            field: 'not_common',
            text: 'Not commonly used',
            icon: 'fa-kit fa-square-nc fa-lg',
          },
          {
            field: 'not_fda_approved',
            text: 'Not FDA approved',
            icon: 'fa-kit fa-square-fda fa-lg',
          },
          {
            field: 'limited_data',
            text: 'Limited data available',
            icon: 'fa-light fa-square-l fa-lg',
          },
        ],
      }
    },
    computed: {
      significant_sources: {
        get () {
          return this.organism.category ? this.organism.category.significant_sources : this.organism.significant_sources
        },
        set (val) {
          this.organism.category ? this.organism.category.significant_sources = val : this.organism.significant_sources = val
        },
      },
      controversial_sources: {
        get () {
          return this.organism.category ? this.organism.category.controversial_sources : this.organism.controversial_sources
        },
        set (val) {
          this.organism.category ? this.organism.category.controversial_sources = val : this.organism.controversial_sources = val
        },
      },
      priority: {
        get () {
          return this.organism.category ? this.organism.category.priority : this.organism.priority
        },
        set (val) {
          this.organism.category ? this.organism.category.priority = val : this.organism.priority = val
        },
      },
      computed_score_factors: {
        get () {
          return this.organism.category ? this.organism.category.score_factors : this.organism.score_factors || []
        },
        set (val) {
          this.organism.category ? this.organism.category.score_factors = val : this.organism.score_factors = val
        },
      },
      computed_precautions: {
        get () {
          return this.organism.category ? this.organism.category.precautions : this.organism.precautions
        },
        set (val) {
          this.organism.category ? this.organism.category.precautions = val : this.organism.precautions = val
        },
      },
      relevant_sources () {
        return this.significant_sources ? this.significant_sources.concat(this.controversial_sources) : []
      },
      categorySearch: {
        get () {
          return this.organism && this.organism.category ? this.organism.category.name : null
        },
        set (val) {
          this.categorySearchText = val
        },
      },

    },
    watch: {
      categorySearchText () {
        if (!this.organism.category || this.categorySearchText != this.organism.category.name) {
            this.fetchCategories(this.categorySearchText)
        }
      },
      loading () {
        this.$emit('loading', this.loading)
      },
      'organism.description' () {
        if (this.organism.description.length == 0) {
          this.$set(this.organism, 'description', { [this.lang]:'' })
        }
      },
      'organism.category.id' (id) {
        if (id) {
          this.fetchCategory(id)
        }
      },
    },
    created () {
      this.fetchCategories(this.organism.category ? this.organism.category.name : null);
      this.axios.get('admin/resistances', { params: { count: 1000, sort: 'name' } }).then((response) => {
        this.resistances = response.data.data
      });
    },
    methods: {
      resistanceLevelIcon (level) {
        switch (level) {
          case 1:
          case 2:
          case 4:
            return 'far fa-shield-virus'
          case 3:
          case 5:
            return 'fa fa-shield-virus'
        }
      },
      resistanceLevelColor (level) {
        switch (level) {
          case 1:
            return 'teal'
          case 2:
          case 3:
            return 'orange darken-1'
          case 4:
          case 5:
            return 'error lighten-1'
        }
      },
      updateOrganism () {
        this.loading = true
        this.axios.patch('admin/organisms/' + this.organism.id, this.organism)
          .then(() => {
            this.$emit('organism-updated')
            this.$toast.success('Organism updated')
          })
          .catch(error => {
            this.$toast.error(error)
          })
          .finally(() => {
            this.loading = false
          })
      },
      fetchCategories (search) {
        this.categoryLoading = true
        const promise = this.axios.get('admin/organisms/categories', {
          params:
            {
              count: 20,
              search: search,
            },
        })
        return promise.then((response) => {
          this.categories = response.data.data;
          this.categoryLoading = false;
        }).catch(error => {
          this.$toast.error(error)
        })
      },
      fetchCategory (id) {
        const promise = this.axios.get('admin/organisms/categories/' + id)
        return promise.then((res) => {
          this.$set(this.organism, 'category', res.data)
          this.$delete(this.organism.category.effective_drugs)
          this.$set(this.organism, 'effective_drugs', res.data.effective_drugs)
          this.$set(this.organism, 'relevant_resistances', res.data.relevant_resistance)
        }).catch(error => {
          this.$toast.error(error)
        })
      },
      addCategory () {
        this.newCategoryLoading = true
        this.axios.post('admin/organisms/categories', { name: this.newCategoryName })
          .then((res) => {
            this.fetchCategories(this.newCategoryName)
            this.organism.category = res.data.data
            this.newCategoryDialog = false
          })
          .catch(error => {
            this.$toast.error(error)
          })
          .finally(() => {
            this.newCategoryLoading = false
          })
      },
      updateEffectiveDrugs(drugs, source) {
        if (Array.isArray(this.organism.effective_drugs) && !this.organism.effective_drugs.length) {
          this.organism.effective_drugs = {}
        }

        drugs = drugs.map((x, index) => {
          if (!x.pivot) {
            x.pivot = { source: source }
            this.pivotFactors.map(x => x.field).forEach(factor => {
              x.pivot[factor] = false
            })
          }
          x.pivot.order = index
          return x
        })
        // this.organism.effective_drugs = this.organism.effective_drugs.filter(x => x.pivot.source != source).concat(drugs)
        this.organism.effective_drugs[source] = drugs
      },
    },
  }
</script>
<style scoped>
  .small-text {
      font-size: 11pt !important;
  }
  .v-textarea textarea {
      line-height: 1rem !important;
  }
</style>
<style>
  .sortable-ghost {
    opacity: 0.5;
    background: #ddd;
  }
  .sortable-drag {
    opacity: 0.5;
    background: #fff;
  }
</style>
